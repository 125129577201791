import React from 'react';
import dataProvider from './dataProvider';
import { authProvider } from './authProvider';
import { Admin, Resource } from 'react-admin';
import { createBrowserHistory as createHistory } from 'history';
import SiteList from './componentes/sites/SiteList'
import SiteShow from './componentes/sites/SiteShow'
import SiteEdit from './componentes/sites/SiteEdit'
import SiteCreate from './componentes/sites/SiteCreate'
import EmailList from './componentes/emails/EmailList'
import EmailShow from './componentes/emails/EmailShow'
import EmailEdit from './componentes/emails/EmailEdit'
import EmailCreate from './componentes/emails/EmailCreate'
import CarList from './componentes/cars/CarList'
import CarShow from './componentes/cars/CarShow'
import CarEdit from './componentes/cars/CarEdit'
import CarCreate from './componentes/cars/CarCreate'
import ImageList from './componentes/images/ImageList'
import ImageShow from './componentes/images/ImageShow'
import ImageCreate from './componentes/images/ImageCreate'
import { PromotionList, PromotionShow, PromotionCreate, PromotionEdit } from './componentes/promotions'
const history = createHistory();

const App = () => {
  return (
    <Admin authProvider={authProvider} history={history} dataProvider={dataProvider}>
      <Resource
        name="sites"
        create={SiteCreate}
        list={SiteList}
        show={SiteShow}
        edit={SiteEdit}
      />
      <Resource
        name="images"
        create={ImageCreate}
        list={ImageList}
        show={ImageShow}
      />
      <Resource
        name="contact_mails"
        create={EmailCreate}
        list={EmailList}
        show={EmailShow}
        edit={EmailEdit}
      />
      <Resource
        name="cars"
        create={CarCreate}
        list={CarList}
        show={CarShow}
        edit={CarEdit}
      />
      <Resource
        name="promotions"
        create={PromotionCreate}
        list={PromotionList}
        show={PromotionShow}
        edit={PromotionEdit}
      />
    </Admin>
  );
}

export default App;
