import { fetchUtils, AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';

const apiUrl = 'https://apiebookfordadmin.goodhumans.mx';
const httpClient = fetchUtils.fetchJson;

export const authProvider = (type, params) => {
  if (type === AUTH_LOGIN) {
    return httpClient(`${apiUrl}/authenticate`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => {
      if (json.message){
        return Promise.reject(json.message);
      }

      localStorage.setItem('username', json.username);
      return Promise.resolve();
    })
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('username');
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      return Promise.reject()
    }
    return Promise.resolve()
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem('username') ?
      Promise.resolve() :
      Promise.reject();
  }
  return Promise.reject('Unknown Method');
};