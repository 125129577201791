import React from 'react';
import { Create, AutocompleteInput, SimpleForm, TextInput } from 'react-admin';

const EmailCreate = (props) => {
  const positions = [
    { id: 'To', name: 'To' },
    { id: 'Copia', name: 'Copia' },
    { id: 'Copia Oculta', name: 'Copia Oculta' }
  ];
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="email" />
        <AutocompleteInput source="position" choices={positions} />
      </SimpleForm>
    </Create>
  );
};

export default EmailCreate;