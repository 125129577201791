import React from 'react';
import { Edit, AutocompleteInput, SimpleForm, TextInput } from 'react-admin';

const EmailEdit = (props) => {
  const positions = [
    { id: 'To', name: 'To' },
    { id: 'Copia', name: 'Copia' },
    { id: 'Copia Oculta', name: 'Copia Oculta' }
  ];
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput source="name" />
        <TextInput source="email" />
        <AutocompleteInput source="position" choices={positions} />
      </SimpleForm>
    </Edit>
  );
};

export default EmailEdit;