import React from 'react';
import { Show, TabbedShowLayout, Tab, TextField, ImageField  } from 'react-admin';

const ImageEdit = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label='Informacion basica'>
          <TextField source="reftype" />
          <ImageField source="src" title="filepath" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ImageEdit;