import React from 'react';
import { Show, TabbedShowLayout, Tab, TextField, ImageField } from 'react-admin';

const NotesEdit = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label='Informacion basica'>
          <TextField source="name" />
          <TextField source="cotiza" />
          <TextField source="manejo" />
          <TextField source="more" />
          <TextField source="terms" />
          <ImageField source="images" src="src" title="id" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default NotesEdit;