import React from 'react';
import { Create, SimpleForm, AutocompleteInput, ImageInput, ImageField } from 'react-admin';

const ImageCreate = (props) => {
  const reftypes = [
    { id: 'opengraph', name: 'Opengraph' },
    { id: 'portada', name: 'Portada' }
  ];

  return (
    <Create {...props}>
      <SimpleForm>
        <AutocompleteInput source="reftype" choices={reftypes} />
        <ImageInput source="images" label="Imagen" accept="image/*" placeholder={<p>Carga aqui</p>}>
          <ImageField source="src" title="filepath" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default ImageCreate;