import React from 'react';
import { Show, TabbedShowLayout, ArrayField, SingleFieldList, ChipField,
  Tab, TextField, ImageField } from 'react-admin';

const NotesEdit = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label='Informacion basica'>
          <TextField disabled source="id" />
          <TextField required source="url" />
          <TextField required source="title" />
          <TextField required source="headTitle" fullWidth={true} />
          <TextField required source="terms" multiline fullWidth={true} />
          <TextField required source="facebook" />
          <TextField required source="whatsapp" />
          <TextField required source="folderName" />
          <ArrayField source="contact_mails">
            <SingleFieldList>
              <ChipField source="email" />
            </SingleFieldList>
          </ArrayField>
          <ImageField source="images" src="src" title="id" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default NotesEdit;