import React from 'react';
import { Edit, SimpleForm, BooleanInput, TextInput, ImageInput, ImageField } from 'react-admin';
import EmailReferenceInput from '../utils/EmailReferenceInput';
import CarReferenceInput from '../utils/CarReferenceInput';
import { PromotionReferenceInput } from '../promotions';


const SitesEdit = (props) => {
  return (
    <>
      <Edit {...props}>
        <SimpleForm>
          <TextInput disabled source="id" />
          <BooleanInput required source="active" />
          <TextInput required source="url" />
          <TextInput required source="map" />
          <TextInput source="mapurl" />
          <TextInput source="waze" />
          <TextInput required source="title" />
          <TextInput required source="headTitle" fullWidth={true} />
          <TextInput required source="terms" multiline fullWidth={true} />
          <TextInput required source="facebook" />
          <TextInput required source="whatsapp" />
          <TextInput required source="folderName" />
          <PromotionReferenceInput filter={{ all: true }} reference="promotions" source="promotions" label="Promociones" />
          <EmailReferenceInput filter={{ all: true }} reference="contact_mails" source="contact_mails_ids" label="Emails" />
          <CarReferenceInput filter={{ all: true }} reference="cars" source="cars" label="Cars" />
          <ImageInput source="images" label="Imagen opengraph" accept="image/*" placeholder={<p>Drop your file here</p>}>
            <ImageField source="opengraph.src" title="opengraph.filepath" />
          </ImageInput>
          <ImageInput source="images" label="Imagen portada" accept="image/*" placeholder={<p>Drop your file here</p>}>
            <ImageField source="portada.src" title="portada.filepath" />
          </ImageInput>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default SitesEdit;
