import * as React from 'react';
import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  input: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '50%',
  },
});

const CarReferenceInput = ({ ...props }) => {
  const classes = useStyles();
  const optionRenderer = car => {
    if (!car)
      return '';

    return `${car.name} ${car.extra_data}`;
  }

  return (
    <div className={classes.input}>
      <ReferenceArrayInput {...props} >
        <AutocompleteArrayInput optionText={optionRenderer} />
      </ReferenceArrayInput>
    </div>
  );
};

export default CarReferenceInput;