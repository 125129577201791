import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  DeleteButton,
} from 'react-admin';

const EmailList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="position" />
        <ShowButton label="Show" basePath="/contact_mails" />
        <EditButton label="Edit" basePath="/contact_mails" />
        <DeleteButton label="Delete" basePath="/contact_mails" />
      </Datagrid>
    </List>
  );
};

export default EmailList;