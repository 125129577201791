import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = 'https://apiebookfordadmin.goodhumans.mx';
const httpClient = fetchUtils.fetchJson;

function addRefTypeImage(url, data, rawData, refType, fullUrl = false){
    let formData = new FormData();
    formData.append('file', rawData);
    return uploadImage(formData, url, data, refType, fullUrl);
}

function uploadImage(formData, url, data, refType, fullUrl){
    return new Promise((resolve, reject) => {
        return httpClient(`${apiUrl}/images`, {
            method: 'POST',
            body: formData,
        }).then(({ json }) => {
            data.reftype = refType;
            data.image_id = json.id;
            if (fullUrl)
                url += `/${json.id}`;
            return httpClient(url, {
                method: 'PATCH',
                body: JSON.stringify(data),
            }).then(({ json }) => (resolve({ data: json })));
        });
    })
}

const dataProvider = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            page: page,
            pageSize: perPage,
            sortBy: field,
            sortOrder: order
        };

        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ headers, json }) => {
            if (!json.range)
                return {data: json, total: json.count};

            if (json.length === 0)
                return {
                    data: [],
                    total: 0
                }
            return {
                data: json[Object.keys(json)[0]].map((resource) => ({ ...resource })),
                total: json.range.total
            }
        });
    },
    getOne: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: { ...json },
        }))
    },
    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids.map( object => object.id ? object.id : object) }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => {
            let objectKey = Object.keys(json)[0];
            if (!objectKey)
                return {data: []};

            return {
                data: json[objectKey].map((resource) => (resource.id)),
            }
        });
    },
    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.map((resource) => ({ ...resource, id: resource._id })),
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },
    update: (resource, params) => {
        let promises = [];
        var url = `${apiUrl}/${resource}/${params.id}`;
        if (params.data['image']) {
            let formData = new FormData();
            formData.append('file', params.data.image.rawFile);
            delete params.data.image;
            promises.push(uploadImage(formData, url, params.data));
        }
        else if (params.data.images.rawFile){
            let formData = new FormData();
            formData.append('file', params.data.images.rawFile);
            delete params.data.images;
            promises.push(uploadImage(formData, url, params.data));
        }
        else if (
            params.data.images.opengraph && params.data.images.portada &&
            params.data.images.opengraph.rawFile && params.data.images.portada.rawFile
        ){
            promises.push(addRefTypeImage(url, params.data, params.data.opengraph.rawFile, 'opengraph'));
            promises.push(addRefTypeImage(url, params.data, params.data.portada.rawFile, 'portada'));
        }
        else if (params.data.images.opengraph && params.data.images.opengraph.rawFile){
            promises.push(addRefTypeImage(url, params.data, params.data.opengraph.rawFile, 'opengraph'));
        }
        else if (params.data.images.portada && params.data.images.portada.rawFile){
            promises.push(addRefTypeImage(url, params.data, params.data.portada.rawFile, 'portada'));
        }
        else {
            return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PATCH',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json }));
        }
        if (promises.length > 0)
            return Promise.all(promises)
                .then(responses => ({ data: {id: 1} }))
                .catch(err => {
                    console.log(err);
                })
    },
    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
    create: (resource, params) => {
        let promise = [];
        let url = `${apiUrl}/${resource}`;
        if (params.data.images){
            if (resource === 'images')
                promise.push(addRefTypeImage(url, params.data, params.data.images.rawFile, params.data.reftype, true));
            else {
                return httpClient(`${apiUrl}/${resource}`, {
                    method: 'POST',
                    body: JSON.stringify(params.data)
                })
                    .then(({json}) => {
                        let formData = new FormData();
                        formData.append('file', params.data.images.rawFile);
                        url += `/${json.id}`;
                        uploadImage(formData, url, params.data)
                            .then(data => {
                                debugger
                                return {data: { ...params.data, id: data.id }}
                            })
                    })
            }
        } else {
            promise.push(httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data)
            }));
        }
        return Promise.all(promise).then((responses) => ({
            data: { ...params.data, id: responses[0].data.id },
        }))
    },
    delete: (resource, params) => (
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
            body: JSON.stringify(params.id),
        }).then(({ json }) => {
            return {data: {...json, id: json.id }}
        })
    ),
    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
}

export default dataProvider;
