import * as React from 'react';
import {
    List,
    Datagrid,
    Show,
    TabbedShowLayout,
    Tab,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    TextField,
    ImageInput,
    ImageField,
    ShowButton,
    EditButton,
    DeleteButton,
    AutocompleteArrayInput,
    ReferenceArrayInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  input: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '50%',
  },
});

export const PromotionList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <ImageField source="image.src" title="filepath" />
        <ShowButton label="Show" basePath="/promotions" />
        <EditButton label="Edit" basePath="/promotions" />
        <DeleteButton label="Delete" basePath="/promotions" />
      </Datagrid>
    </List>
  );
};

export const PromotionShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label='Informacion basica'>
            <TextField source="id" />
            <TextField source="name" />
            <ImageField source="image.src" title="filepath" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const PromotionCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name" />
                <ImageInput source="images" label="Imagen para promo" accept="image/*" placeholder={<p>Drop your file here</p>}>
                    <ImageField source="src" title="filepath" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};

export const PromotionEdit = (props) => {
  return (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ImageInput source="image" label="Imagen para promo" accept="image/*" placeholder={<p>Drop your file here</p>}>
                <ImageField source="src" title="filepath" />
            </ImageInput>
        </SimpleForm>
    </Edit>
  );
};

export const PromotionReferenceInput = ({ ...props }) => {
  const classes = useStyles();
  const optionRenderer = promotion => {
    if (!promotion)
      return '';

    return `${promotion.id} ${promotion.name}`;
  }

  return (
    <div className={classes.input}>
      <ReferenceArrayInput {...props} >
        <AutocompleteArrayInput optionText={optionRenderer} />
      </ReferenceArrayInput>
    </div>
  );
};
