import React from 'react';
import { Show, TabbedShowLayout, Tab, TextField } from 'react-admin';

const NotesEdit = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label='Informacion basica'>
          <TextField disabled source="id" />
          <TextField source="name" />
          <TextField source="email" />
          <TextField source="position" />           
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default NotesEdit;