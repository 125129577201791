import React from 'react';
import { Create, SimpleForm, TextInput, ImageInput, ImageField } from 'react-admin';

const CarCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="extra_data" />
        <TextInput source="cotiza" />
        <TextInput source="manejo" />
        <TextInput source="more" />
        <TextInput source="terms" />
        <TextInput source="menu_position" type="number" />
        <ImageInput source="images" label="Imagen para carro" accept="image/*" placeholder={<p>Drop your file here</p>}>
          <ImageField source="src" title="filepath" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default CarCreate;