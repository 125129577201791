import React from 'react';
import { Create, SimpleForm, TextInput, ImageInput, ImageField } from 'react-admin';
import EmailReferenceInput from '../utils/EmailReferenceInput';
import CarReferenceInput from '../utils/CarReferenceInput';

const EmailCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
          <TextInput required source="url" />
          <TextInput required source="title" />
          <TextInput source="mapurl" />
          <TextInput source="waze" />
          <TextInput required source="headTitle" fullWidth={true} />
          <TextInput required source="terms" multiline fullWidth={true} />
          <TextInput required source="facebook" />
          <TextInput required source="whatsapp" />
          <TextInput required source="folderName" />
          <EmailReferenceInput filter={{ all: true }} reference="contact_mails" source="contact_mails_ids" label="Emails" />
          <CarReferenceInput filter={{ all: true }} reference="cars" source="cars" label="Cars" />
          <ImageInput source="images.opengraph" label="Imagen opengraph" accept="image/*" placeholder={<p>Carga el opengraph aqui</p>}>
            <ImageField source="src" title="filepath" />
          </ImageInput>
          <ImageInput source="images.portada" label="Imagen portada" accept="image/*" placeholder={<p>Carga la imagen de portada aqui</p>}>
            <ImageField source="src" title="filepath" />
          </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default EmailCreate;
