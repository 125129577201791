import React from 'react';
import {
  List, ArrayField, SingleFieldList, ChipField,
  Datagrid, BooleanField, TextField, ImageField,
  EditButton, ShowButton, DeleteButton,
} from 'react-admin';
import Button from '@material-ui/core/Button';

const PreviewSiteButton = ({ record }) => (
  <Button color="secondary" href={`https://ebookford.com/preview/index.php?site=${record.folderName}`} target="_blank">Ver como quedara</Button>
);

const SiteList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <ImageField source="images.opengraph.src" title="opengraph.filepath" label='opengraph' />
        <ImageField source="images.portada.src" title="opengraph.filepath" label='cover' />
        <TextField source="folderName" />
        <BooleanField source="active" />
        <ArrayField source="cars">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="contact_mails">
          <SingleFieldList>
            <ChipField source="email" />
          </SingleFieldList>
        </ArrayField>
        <ShowButton label="Show" basePath="/sites" />
        <EditButton label="Edit" basePath="/sites" />
        <DeleteButton label="Delete" basePath="/sites" />
        <PreviewSiteButton />
      </Datagrid>
    </List>
  );
};

export default SiteList;